// By default, Klaro will load the config from  a global "klaroConfig" variable.
// You can change this by specifying the "data-config" attribute on your
// script take, e.g. like this:
// <script src="klaro.js" data-config="myConfigVariableName" />
// You can also disable auto-loading of the consent notice by adding
// data-no-auto-load=true to the script tag.
var klaroConfig = {
    // You can customize the ID of the DIV element that Klaro will create
    // when starting up. If undefined, Klaro will use 'klaro'.
    elementID: 'klaro',

    // How Klaro should store the user's preferences. It can be either 'cookie'
    // (the default) or 'localStorage'.
    storageMethod: 'cookie',

    // You can customize the name of the cookie that Klaro uses for storing
    // user consent decisions. If undefined, Klaro will use 'klaro'.
    cookieName: 'klaro_session',

    // You can also set a custom expiration time for the Klaro cookie.
    // By default, it will expire after 120 days.
    cookieExpiresAfterDays: 1,

    // You can change to cookie domain for the consent manager itself.
    // Use this if you want to get consent once for multiple matching domains.
    // If undefined, Klaro will use the current domain.
    //cookieDomain: '.github.com',

    // Put a link to your privacy policy here (relative or absolute).
    privacyPolicy: 'https://www.youthquake.it/it/privacy-policy',

    // Defines the default state for applications (true=enabled by default).
    default: false,

    // If "mustConsent" is set to true, Klaro will directly display the consent
    // manager modal and not allow the user to close it before having actively
    // consented or declines the use of third-party apps.
    mustConsent: false,

    // Show "accept all" to accept all apps instead of "ok" that only accepts
    // required and "default: true" apps
    acceptAll: true,

    // replace "decline" with cookie manager modal
    hideDeclineAll: true,

    // You can define the UI language directly here. If undefined, Klaro will
    // use the value given in the global "lang" variable. If that does
    // not exist, it will use the value given in the "lang" attribute of your
    // HTML tag. If that also doesn't exist, it will use 'en'.
    lang: 'it',

    // You can overwrite existing translations and add translations for your
    // app descriptions and purposes. See `src/translations/` for a full
    // list of translations that can be overwritten:
    // https://github.com/KIProtect/klaro/tree/master/src/translations

    // Example config that shows how to overwrite translations:
    // https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js
    translations: {
        it: {
            consentModal: {
                description: "Proseguendo la navigazione senza esprimere una scelta acconsenti all'uso dei cookie tecnici. Questo sito può utilizzare anche cookie di profilazione di terze parti per inviarti messaggi pubblicitari mirati in linea con le tue preferenze. Se acconsenti clicca su 'Accetta tutti', se invece vuoi negare il consenso a tutti o ad alcuni cookie modifica le impostazioni qui in basso e clicca su 'Accetta selezionati'.",
                privacyPolicy: {
                    name: 'Informativa Privacy',
                    text: "Per saperne di più, leggi la nostra {privacyPolicy}, al cui interno troverai il link alla nostra policy sui Cookies."
                }
            },
            consentNotice: {
                description: "Proseguendo la navigazione acconsenti all'uso dei cookie tecnici. Questo sito può utilizzare anche cookie di profilazione di terze parti per inviarti messaggi pubblicitari mirati in linea con le tue preferenze. Se acconsenti clicca su 'Accetta tutti', se invece vuoi saperne di più o vuoi negare il consenso a tutti o ad alcuni cookie clicca su 'Scopri di più'."
            },
            google: {
                description: 'Cookie di profilazione con finalità analitiche e di profilazione.'
            },
            tecnhicalCookies: {
                description: 'Cookie tecnico per il mantenimento della sessione nel portale.'
            },
            googleAnonymous: {
                description: 'Cookie tecnico con finalità analitiche.'
            },
            numeroBlu: {
                description: 'Cookie di profilazione con finalità analitiche e di profilazione.'
            },
            linkedin: {
                description: 'Cookie di profilazione con finalità analitiche e di profilazione.'
            },
            instapage: {
                description: 'Cookie di profilazione con finalità analitiche e di profilazione.'
            },
            facebook: {
                description: 'Cookie di profilazione con finalità analitiche e di profilazione.'
            },
            adroll: {
                description: 'Cookie di profilazione con finalità analitiche e di profilazione.'
            },
            purposes: {
                profilazione: 'Profilazione',
                analitico: 'Analitico',
                security: 'Sicurezza',
                sessione: 'Sessione',
                livechat: 'Livechat',
                advertising: 'Advertising',
                styling: 'Stile',
                technicalCookies: 'Sessione'
            },
            acceptAll: 'Accetta tutti',
            acceptSelected: 'Accetta selezionati',
        }

    },

    // This is a list of third-party apps that Klaro will manage for you.
    apps: [
        {
            name: 'google',
            title: 'Google Analytics (non anonimizzato)',
            default: true,
            purposes: ['analitico', 'profilazione'],
            cookies: [['__utma', '/', '.youthquake.it'], ['__utmb', '/', '.youthquake.it'], ['__utmc', '/', '.youthquake.it'],
                ['__utmt', '/', '.youthquake.it'], ['__utmz', '/', '.youthquake.it'], ['_ga', '/', '.youthquake.it'],
                ['_gid', '/', '.youthquake.it'], [/^_gat.*$/i, '/', '.youthquake.it'], [/^_gcl.*$/i, '/', '.youthquake.it']],
        },
        {
            name: 'facebook',
            default: true,
            title: 'Facebook',
            purposes: ['analitico', 'profilazione'],
            cookies: [['fr', '/', '.youthquake.it'], ['tr', '/', '.youthquake.it'], ['_fbp', '/', '.youthquake.it']],
        },
        {
            name: 'instapage',
            default: true,
            title: 'Instapage',
            purposes: ['analitico', 'profilazione'],
            cookies: ['instapage-variant-*']
        },
        {
            name: 'googleAnonymous',
            title: 'Google Analytics (anonimizzato)',
            required: true,
            purposes: ['analitico'],
            // cookies: ['__youthquake_session']
        }
    ]
};